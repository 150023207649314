// window.lazySizesConfig = {
//    init: false,
//    lazyClass: 'lazy',
//    loadingClass: 'lazy-loading',
//    loadedClass: 'lazy-loaded',
// };

lazySizes.init();
document.addEventListener('lazybeforeunveil', function (e) {
	var bg = e.target.getAttribute('data-bg');
	if (bg) {
		e.target.style.backgroundImage = 'url(' + bg + ')';
	}
});

jQuery(function ($) {

	/*-------------
	 *
	 *  SMARTRESIZE
	 *  https://gist.github.com/raspo/9755313
	 *
	 *-------------*/

	;
	(function ($, sr) {
		// debouncing function from John Hann
		// http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
		var debounce = function (func, threshold, execAsap) {
			var timeout;

			return function debounced() {
				var obj = this,
					args = arguments;

				function delayed() {
					if (!execAsap)
						func.apply(obj, args);
					timeout = null;
				};

				if (timeout)
					clearTimeout(timeout);
				else if (execAsap)
					func.apply(obj, args);

				timeout = setTimeout(delayed, threshold || 100);
			};
		}
		// smartresize
		jQuery.fn[sr] = function (fn) {
			return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr);
		};

	})(jQuery, 'smartresize');

	/*-------------
	 *
	 *  100vh FIX
	 *  https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
	 *  calc(var(--vh, 1vh) * 100)
	 *
	 *-------------*/

	var vh = window.innerHeight * 0.01;
	// Then we set the value in the --vh custom property to the root of the document
	document.documentElement.style.setProperty('--vh', vh + 'px');

	$(window).on('load', function () {
		vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', vh + 'px');
	});
	// $(window).smartresize(function () {
	// 	vh = window.innerHeight * 0.01;
	// 	document.documentElement.style.setProperty('--vh', vh + 'px');
	// });

	/*-------------
	 *
	 *  Remove Comments
	 *
	 *-------------*/


	function removeComments() {
		$('*').contents().each(function () {
			if (this.nodeType === Node.COMMENT_NODE) {
				$(this).remove();
			}
		});
	}
	//removeComments();

	/*-------------
	 *
	 *  isMobile
	 *
	 *-------------*/

	var isMobile = ($(window).innerWidth() < 1024) ? true : false;

	$(window).smartresize(function () {
		isMobile = ($(window).innerWidth() < 1024) ? true : false;
	});


	// ----------------------------------------------
	//
	// LAZY LOAD
	//
	// ----------------------------------------------



	$(document).ready(function () {
		if (isMobile) {
			var lazythreshold = 50;
		} else {
			var lazythreshold = 500;
		}
		// $('img.lazy').unveil(lazythreshold,function(){
		//    $(this).load(function() {
		//       $(this).addClass('lazy-loaded').removeClass('lazy');
		//    });
		// });

		// use .lazy instead of .lazyload

	});



	/*-------------
	 *
	 *  FUNCIONES UTILES
	 *
	 *-------------*/

	// validateEmail(email)
	function validateEmail(email) {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	// enlaces externos
	// $(this).isExternal()
	$.fn.isExternal = function () {
		var host = window.location.host;
		var link = $('<a>', {
			href: this.attr('href'),
		})[0].hostname;
		if ($(this).attr('target') == '_blank') return true;
		return (link !== host);
	};


});

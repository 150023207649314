// @codekit-prepend "utils.js"

jQuery(function ($) {

	/*-------------
	 *
	 *  isMobile
	 *
	 *-------------*/

	var isMobile = ($(window).innerWidth() < 1024) ? true : false;

	$(window).smartresize(function () {
		isMobile = ($(window).innerWidth() < 1024) ? true : false;
	});

	/*-------------
	 *
	 *  SCROLL TO
	 *
	 *-------------*/
	$('.scroll-to').on('click', function () {
		var scrollto = $(this).attr('data-scroll-to');
		var scrollOffset = $(this).attr('data-scroll-offset') ? $(this).attr('data-scroll-offset') : '0';
		if ($(scrollto).length) {
			var maxTime = isMobile ? 2000 : 3000;
			var pos = (parseInt($(scrollto).offset().top) + parseInt(scrollOffset));
			var currScroll = $(window).scrollTop();
			var distancia = pos - currScroll;
			if (distancia < 0) {
				distancia = distancia * -1;
			}
			var scrollTime = distancia * 2;
			if (scrollTime > maxTime) {
				scrollTime = maxTime;
			}
			if (scrollTime < 500) {
				scrollTime = 500;
			}
			$('body').addClass('is-animating');
			$('html, body').animate({
				scrollTop: pos
			}, scrollTime, 'easeInOutExpo', function () {
				$('body').removeClass('is-animating');
				if ($('body.menu-active').length) {
					$('body, html').removeClass('menu-active');
				}
			});
		}
	});

	/*--------------------------
	 *
	 *  HEADER MENU MOBILE
	 *
	 *--------------------------*/
	$('.header-icon').on('click', function () {
		$('body, html').toggleClass('menu-active');
	});


	function throttle(fn, wait) {
		var time = Date.now();
		return function () {
			if ((time + wait - Date.now()) < 0) {
				fn();
				time = Date.now();
			}
		}
	}
	$(window).on('scroll', throttle(checkMobileScroll, 50));


	function checkMobileScroll() {
		var sTop = $(document).innerHeight();
		var sCurr = $(window).scrollTop();
		var sTrans = Math.round((sCurr * 360) / sTop);
		if (sTrans > 320) {
			sTrans = 360;
		} else if (sTrans < 20) {
			sTrans = 0;
		}
		var sRot = 'rotate(' + sTrans + 'deg)';
		$('.header-book-mobile').css("transform", sRot);
	}

	/*--------------------------
	 *
	 *  AOS
	 *
	 *--------------------------*/
	$(window).load(function () {
		AOS.init({
			duration: 750,
			easing: 'easeInOut',
			once: true
		});
	});

	/*--------------------------
	 *
	 *  PAROLLER PARALLAX
	 *
	 *--------------------------*/
	$(window).load(function () {
		$('.is-parallax').paroller();
	});


	/*--------------------------
	 *
	 *  CURSOR
	 *
	 *--------------------------*/

	var $cursor = $('#cursor');

	$('.cursor-hover, .button, a, .menu-item, .scroll-to, .af-submit-button').on('mouseenter mouseleave', function (e) {
		if (e.type == "mouseenter") {
			$cursor.addClass('is-hover');
		} else {
			$cursor.removeClass('is-hover');
		}
	});
	$(document).mouseleave(function () {
		$cursor.addClass('is-hidden');
	});

	let clientX = -100;
	let clientY = -100;
	const innerCursor = document.getElementById("cursor");

	const initCursor = () => {
		// add listener to track the current mouse position
		document.addEventListener("mousemove", e => {
			clientX = e.clientX;
			clientY = e.clientY;
			if ($cursor.hasClass('is-hidden')) {
				$cursor.removeClass('is-hidden');
			}
		});

		const renderCursor = () => {
			innerCursor.style.transform = `translate3d(${clientX}px, ${clientY}px, 0)`;
			requestAnimationFrame(renderCursor);
		};
		requestAnimationFrame(renderCursor);
	};

	initCursor();

	/*--------------------------
	 *
	 *  WAYPOINTS
	 *
	 *--------------------------*/

	$('.block-waypoint').each(function () {
		new Waypoint({
			element: $(this),
			handler: function (direction) {
				var wayp = $(this.element).attr('data-waypoint');
				$('.menu-item.is-active').removeClass('is-active');
				if ($('.menu-item-' + wayp).length) {
					$('.menu-item-' + wayp).addClass('is-active');
				}
			},
			offset: '50%'
		});
	});


	/*--------------------------
	 *
	 *  HEADER BG
	 *
	 *--------------------------*/
	$(window).on('scroll', function () {
		if ($(window).scrollTop() > $(window).innerHeight()) {
			$('.header').addClass('background-active');
		} else {
			$('.header').removeClass('background-active');
		}
	});


	/*--------------------------
	 *
	 *  TOUR BOOK FORM
	 *
	 *--------------------------*/
	$('body').on('focusin focusout change', 'input, textarea', function (e) {
		if (!$(this).attr('type') || $(this).attr('type') != 'checkbox') {
			var dis = $(this);
			var label = $(this).parents('.af-input').siblings('.af-label');
			if (e.type == 'focusin') {
				if (label.length) label.addClass('is-focus');
			} else {
				if (label.length && !dis.val()) label.removeClass('is-focus');
			}
			if (e.type == 'change') {
				if (dis.val()) {
					label.addClass('is-focus');
				}
			}
		}
	});

	/*--------------------------
	 *
	 *  LOADER
	 *
	 *--------------------------*/
	$(window).load(function () {
		$('#loader').fadeOut(function () {
			$(this).remove();
			if ($('.scroll-on-load').length) {
				var scrollto = $('.scroll-on-load');
				var scrollOffset = '-80';
				if ($(scrollto).length) {
					var maxTime = isMobile ? 2000 : 3000;
					var pos = (parseInt($(scrollto).offset().top) + parseInt(scrollOffset));
					var currScroll = $(window).scrollTop();
					var distancia = pos - currScroll;
					if (distancia < 0) {
						distancia = distancia * -1;
					}
					var scrollTime = distancia * 2;
					if (scrollTime > maxTime) {
						scrollTime = maxTime;
					}
					if (scrollTime < 500) {
						scrollTime = 500;
					}
					$('body').addClass('is-animating');
					$('html, body').animate({
						scrollTop: pos
					}, scrollTime, 'easeInOutExpo', function () {
						$('body').removeClass('is-animating');
					});
				}
			}
		});
	});
});
